<template>
  <div class="CorrectQuestopnnaire">
    <div class="container">
      <div class="content-left">
        <div style="position: fixed;width: 300px;height: 100%;">
          <div class="answer">答题卡</div>
          <div class="title-list">
            <ul v-if="infoData && infoData.length != 0">
              <li
                :style="item.Result === 1 ? 'background: #52C41A' : ''"
                v-for="(item, index) in infoData[0].QuestionnaireList"
              >
                <a :href="'#tag' + index">{{ index + 1 }}</a>
              </li>
            </ul>
          </div>
          <div class="example">
            <span class="example-btn">
              <span></span>
              正确
            </span>
            <span class="example-btn">
              <span></span>
              错误
            </span>
          </div>
        </div>
      </div>
      <div class="content-right" v-if="infoData.length > 0">
        <div class="container-header">
          <div class="questionnaire-title">{{ baseInfo.Title }}</div>
          <div class="questionnaire-des">{{ baseInfo.Explain }}</div>
        </div>

        <div
          class="container-content"
          :id="'tag' + index"
          v-for="(item, index) in thisData"
        >
          <div class="content-title flex" style="align-items: end">
            <div class="item-title">
              {{ index + 1 }}. {{ item.OptionTitle }}({{
                item.OptionScore + '分'
              }})
            </div>
            <div class="item-oper">
              <a-icon
                type="check-circle"
                :style="item.Result == 1 ? 'color: #52C41A' : ''"
              />
              <a-icon
                type="close-circle"
                :style="item.Result == 0 ? 'color: #FF3E82' : ''"
              />
              <div class="num">
                <input
                  v-model="item.Score"
                  @keyup="item.Score = item.Score.replace(/[^\d]/g, '')"
                  min="0"
                />
                <span>分</span>
              </div>
            </div>
          </div>
          <div class="content-text">
            <!--单选题-->
            <a-radio-group
              v-if="item.Type == 1 || item.Type == 3"
              v-model="item.AnswerValue"
              disabled
            >
              <div class="item-row" v-for="(tag, i) in item.Children">
                <a-radio
                  :value="tag.OptionTitle"
                  :defaultChecked="tag.selected == 1 ? true : false"
                  >{{ tag.OptionTitle }}</a-radio
                >
              </div>
            </a-radio-group>

            <!--多选题-->
            <a-checkbox-group
              v-if="item.Type == 2"
              v-model="item.AnswerArr"
              disabled
            >
              <div class="item-row" v-for="(tag, i) in item.Children">
                <a-checkbox :value="tag.OptionTitle" style="margin:0">{{
                  tag.OptionTitle
                }}</a-checkbox>
              </div>
            </a-checkbox-group>
            <!--问答题-->
            <a-textarea
              placeholder="请输入"
              readonly
              :value="item.Answer"
              v-if="item.Type == 4"
              :rows="4"
            />
          </div>
          <div class="answer-container">
            <div v-show="item.Type != 4">
              <span class="row-label">考生答案：</span>
              <span
                class="answer-num"
                :style="item.Result == 1 ? 'color:#52C41A' : 'color:#D42020'"
                >{{ item.Answer }}</span
              >
            </div>
            <div>
              <span class="row-label">正确答案：</span>
              <span class="answer-num">{{
                item.Type != 4 ? item.AnswerValue : '此题需人工判分'
              }}</span>
            </div>
            <div>
              <p>解析说明：{{ item.AnalysisValue }}</p>
            </div>
          </div>
          <div class="float-alert" style="bottom: 20px;top: auto;">
            <ul>
              <li v-if="page != 2 && pageIndex != 0">
                <div class="label" @click="_previous()">上一人</div>
              </li>
              <li>
                <div class="label" @click="_next()">下一人</div>
              </li>
            </ul>
            <div>
              <a-button class="close-btn" type="primary" @click="_saveData"
                >保存</a-button
              >
            </div>
          </div>
        </div>
      </div>
      <div class="float-alert">
        <div style="margin-bottom: 10px;">
          <a-button class="close-btn" type="primary" @click="_returnPage"
            >返回</a-button
          >
        </div>
        <ul v-if="infoData.length != 0 || infoData != ''">
          <li>
            <div class="label">考试姓名</div>
            <div class="value ant-menu-item-selected">
              {{ infoData[0].UserName }}
            </div>
          </li>
          <li>
            <div class="label">考试成绩</div>
            <div class="value ant-menu-item-selected">
              {{ infoData[0].Score }}
            </div>
          </li>
          <li>
            <div class="label">考试状态</div>
            <div
              class="value ant-menu-item-selected"
              :style="infoData[0].Result != 1 ? 'color: #FE568F' : ''"
            >
              {{ infoData[0].Result == 1 ? '通过' : '不通过' }}
            </div>
          </li>
          <li>
            <div class="label">仅看错题</div>
            <div class="value">
              <a-switch size="small" v-model="switchBtn" @change="_changeBtn" />
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from 'ant-design-vue'
var user = JSON.parse(window.sessionStorage.getItem('userInfo'))
export default {
  name: 'CorrectQuestopnnaire',
  components: {
    Modal
  },
  data () {
    return {
      infoData: [],
      baseInfo: '',
      page: 1,
      switchBtn: false,
      ar: ['非常满意', '比较满意'],
      thisData: [],
      correctList: [],
      pageIndex: 0
    }
  },
  created () {
    let user = JSON.parse(window.sessionStorage.getItem('userInfo'))
    this.$message.loading('加载中...', 0)
    this.$axios.get(
      5836105,
      {
        Qid: this.$route.query.id,
        UserId: this.$route.query.uid,
        Page: this.page
      },
      res => {
        if (res.data.code == 1) {
          this.infoData = res.data.data
          this.correctList.push(res.data.data[0])
          this.pageIndex = 0
          this.baseInfo = res.data.qtInfo
          this.thisData = this.infoData[0].QuestionnaireList
          this.page += 1
        } else {
          this.$message.error(res.data.msg)
        }
        this.$message.destroy()
      }
    )
  },
  methods: {
    _returnPage () {
      this.$router.push({
        path: '/addCorrect',
        query: { id: this.$route.query.id }
      })
    },
    _previous () {
      if (this.pageIndex - 1 >= 0) {
        this.infoData[0] = this.correctList[this.pageIndex - 1]
        this.thisData = this.correctList[this.pageIndex - 1].QuestionnaireList
        this.pageIndex = this.pageIndex - 1
      }
    },
    _next () {
      let self = this
      console.log(this.infoData[0].Id)
      console.log(this.correctList)
      if (
        this.infoData[0].Id == this.correctList[this.correctList.length - 1].Id
      ) {
        this.$axios.get(
          5836105,
          {
            Qid: this.$route.query.id,
            PrevId: this.infoData[0].UserId,
            Page: this.page
          },
          res => {
            if (res.data.code == 1) {
              this.infoData = res.data.data
              this.correctList.push(res.data.data[0])
              this.baseInfo = res.data.qtInfo
              this.thisData = this.infoData[0].QuestionnaireList
              this.page += 1
              this.pageIndex += 1
              this.$message.destroy()
            } else {
              this.$router.push({
                path: '/addCorrect',
                query: { id: this.$route.query.id }
              })
            }
          }
        )
      } else {
        if (this.pageIndex + 1 <= this.correctList.length - 1) {
          this.infoData[0] = this.correctList[this.pageIndex + 1]
          this.thisData = this.correctList[this.pageIndex + 1].QuestionnaireList
          this.pageIndex += 1
        }
      }
      //如果没保存的话先保存在切换下一个人
      // if(this.correctList.length!=0 && this.infoData[0].Id==this.correctList[this.correctList.length-1].Id){
      //     this.$message.loading('加载中...');
      //     this.$axios.get(5836105,{Qid:this.$route.query.id,PrevId:this.$route.query.uid,Page:this.page},res=>{
      //         if(res.data.code==1){
      //             this.infoData = res.data.data;
      //             this.baseInfo = res.data.qtInfo;
      //             this.thisData = this.infoData[0].QuestionnaireList;
      //         }else{
      //             this.$message.error(res.data.msg);
      //         }
      //         this.$message.destroy();
      //     })
      // }else{
      //     Modal.confirm({
      //         title: '提示',
      //         content: '上一个试卷还没有保存，你确定要保存在跳到下一个吗？',
      //         okText: '确认',
      //         cancelText: '取消',
      //         onOk: ()=>{
      //             self._saveData();
      //         },
      //     });
      // }
    },
    _saveData () {
      let list = []
      let Score = 0
      let listData = this.infoData[0].QuestionnaireList
      for (let i = 0; i < listData.length; i++) {
        list.push({
          AnswerId: listData[i].AnswerId,
          Score: parseInt(listData[i].Score),
          Result: listData[i].Score > 0 ? 1 : 0,
          OptionId: listData[i].OptionId
        })
        Score += parseInt(listData[i].Score)
      }
      let option = [
        {
          Pid: this.infoData[0].Id,
          UserId: this.infoData[0].UserId,
          OptionList: list
        }
      ]
      let data = {
        Qid: this.$route.query.id,
        OperationUserId: user.uid,
        OperationUserName: user.name,
        Option: JSON.stringify(option)
      }

      this.$axios.post(
        5836109,
        {
          Qid: data.Qid,
          OperationUserId: data.OperationUserId,
          OperationUserName: data.OperationUserName,
          Option: data.Option
        },
        res => {
          if (res.data.code == 1) {
            this.$message.success(res.data.msg)
            this.infoData[0].Score = Score
            this.correctList.push(this.infoData[0])
            this._next()
          } else {
            this.$message.error(res.data.msg)
          }
        }
      )
    },
    _changeBtn (val) {
      this.switchBtn = val
      let data = this.infoData[0].QuestionnaireList
      let list = []
      for (let i = 0; i < data.length; i++) {
        if (data[i].Result != 1) {
          list.push(data[i])
        }
      }
      if (val) {
        this.thisData = list
      } else {
        this.thisData = data
      }
    }
  }
}
</script>

<style scoped lang="less">
.CorrectQuestopnnaire {
  height: 100%;
  .container {
    display: flex;
    min-height: 100%;
    .content-left {
      position: relative;
      width: 315px;
      min-height: 100%;
      padding: 30px 15px;
      background: #fff;
      border-radius: 4px;
      .title-list {
        ul {
          width: 100%;
          li {
            display: inline-block;
            vertical-align: top;
            margin-top: 10px;
            margin-right: 15px;
            width: 30px;
            height: 30px;
            line-height: 30px;
            border-radius: 50%;
            text-align: center;
            background: #ff3e82;
            font-size: 14px;
            cursor: pointer;
            a {
              color: #fff;
            }
          }
        }
      }
      .answer {
        font-size: 18px;
        color: #333;
      }
      .example {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 15px;
        border-top: 1px solid #f0f0f0;
        text-align: right;
        .example-btn {
          margin-left: 15px;
          font-size: 14px;
          color: #333;
          span {
            display: inline-block;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            margin-right: 3px;
            background: #52c41a;
          }
        }
        .example-btn:last-child {
          span {
            background: #ff3e82;
          }
        }
      }
    }
    .content-right {
      position: relative;
      flex: 1;
      margin-left: 15px;
      padding-right: 150px;
      .container-header {
        padding: 25px 15px;
        background: #fff;
        border-radius: 4px;
        text-align: center;
        .questionnaire-title {
          width: 100%;
          margin-bottom: 15px;
          border: none;
          font-size: 18px;
          color: #333;
          text-align: center;
          outline: none;
        }
        .questionnaire-des {
          width: 100%;
          color: #999;
          font-size: 16px;
          border: none;
          text-align: center;
          outline: none;
        }
      }
      .container-content {
        padding: 15px;
        background: #fff;
        border-radius: 4px;
        margin-top: 20px;
        .content-title {
          .item-title {
            font-size: 18px;
            color: #333;
          }
          .item-oper {
            white-space: nowrap;
            i {
              font-size: 26px;
              color: #bbb;
              vertical-align: middle;
              margin-right: 6px;
              cursor: pointer;
            }
            .num {
              display: inline-block;
              vertical-align: middle;
              border: 1px solid #e2e2e2;
              border-radius: 5px;
              input {
                display: inline-block;
                width: 50px;
                height: 25px;
                padding: 2px 5px;
                border: none;
                background: transparent;
                outline: none;
              }
              span {
                display: inline-block;
                padding: 2px 6px;
                background: #f9f9f9;
                color: #858585;
              }
            }
          }
        }
        .content-text {
          margin-top: 15px;
          .item-row {
            &:last-child {
              margin-bottom: 0;
            }
            margin-bottom: 20px;
          }
        }
        .answer-container {
          padding: 15px;
          margin-top: 15px;
          background: #f9f9f9;
          border-radius: 4px;
          div {
            margin-bottom: 10px;
          }
          div:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .float-alert {
    position: fixed;
    top: 125px;
    right: 60px;
    ul {
      width: 100px;
      background: #fff;
      border-radius: 4px;
      li {
        padding: 10px 0;
        border-bottom: 1px solid #f0f0f0;
        text-align: center;
        cursor: pointer;
        .label {
          margin-bottom: 4px;
          font-size: 14px;
          color: #666;
        }
        .value {
          font-size: 14px;
        }
      }
    }
    .close-btn {
      margin-top: 15px;
      width: 100%;
    }
  }
}
</style>
